<template>
  <div class="navView">
    <div class="navigatList flex alignCenter">
      <div class="centerWidth flex spacebetween alignCenter marginAuto">
        <span class="navTitle">{{titleName}}</span>
        <div class="flex spacebetween alignCenter ">
          <div class="flex alignCenter navListName cursorP"
               @click="goother({path:'/'})">
            <img src="@/assets/img/common/homeIcon.png"
                 style="width:11px;height:11px;margin-right:7px;"
                 alt="">
            <span style="font-size:12px;">首页</span>
          </div>
          <i class="el-icon-arrow-right"
             style="font-size:12px;"></i>
          <div class="flex alignCenter"
               v-for="(im,ix) in navList"
               :key="ix">
            <div class="flex alignCenter navListName cursorP"
                 @click="goother({path: im.fullPath})">
              <span style="font-size:12px;">{{im.meta.title}}</span>
            </div>
            <i class="el-icon-arrow-right"
               style="font-size:12px;"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'navView',
  props: {
    titleName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  created () {
    // console.log( this.$store.getters.navList)
    // console.log(this.navList)
  },
  computed: {
    navList () {
      return this.$store.getters.navList
    }
  }
}
</script>
<style lang="scss" scoped>
.navigatList {
  width: 100%;
  background: #47d7e3;
  height: 67px;
  color: #fff;
  .navTitle {
    font-size: 24px;
  }
}
.navListName {
  margin-right: 8px;
  margin-left: 8px;
}
</style>