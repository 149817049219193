<template>
<!-- 课程详情 广告详情 -->
    <div class="lect">
		<nav-view :titleName="'详情'"></nav-view>
        <p v-html="detailWhole.advert_info.content" style="min-height: 500px;margin-top: 30px;"></p>
    </div>
</template>

<script>
	import navView from '@/components/navView.vue'
import { course_info } from '@/utils/Api/play'
export default {
	components:{navView},
    data() {
        return {
            content:null,
            detailWhole:null,
        };
    },
    created(){
        console.log('用户',this.$route.query)
        this.id = this.$route.query.id
        this.details()
    },
    methods: {
        async details(){
            this.detailWhole = await course_info({course_id:this.id})
        }
    },
};
</script>

<style>
.lect {
    width: 1200px;
    margin: 0 auto;
    /* min-width: 500px; */
    padding: 0 30px;
    /* overflow-y: auto; */
    /* text-align: initial ; */
}
</style>